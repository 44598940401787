import styled from 'styled-components';

const Loader = () => {
	return (
		<Wrapper>
			<ul className='loader'>
				<li></li>
				<li></li>
				<li></li>
			</ul>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	width: 100%;
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
	.loader {
		display: flex;
		width: 70px;
		height: 50px;
		list-style: none;

		@keyframes loadbars {
			0% {
				height: 10px;
				margin-top: 25px;
			}
			50% {
				height: 50px;
				margin-top: 0px;
			}
			100% {
				height: 10px;
				margin-top: 25px;
			}
		}

		li {
			background-color: #ddd;
			width: 10px;
			height: 10px;
			margin-right: 5px;
		}
		li:first-child {
			animation: loadbars 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) infinite 0s;
		}
		li:nth-child(2) {
			animation: loadbars 0.6s ease-in-out infinite -0.2s;
		}
		li:nth-child(3) {
			animation: loadbars 0.6s ease-in-out infinite -0.4s;
		}
	}
`;
export default Loader;
