import CategoryTabBar from '../CategoryTab';
import MultipleScreensInCategory from '../MultipleScreensInCategory';
import SearchTabBar from '../SearchTabBar';
import Tab from '../TabSection';

const ScreensTab = ({ screens }) => {
	return (
		<>
			{/* <SearchTabBar /> */}
			{/* <Tab/> */}
			<CategoryTabBar />
			<MultipleScreensInCategory screens={screens} />
		</>
	);
};

export default ScreensTab;
