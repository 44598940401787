import Link from 'next/link';
import styled from 'styled-components';
import EmptyState from '../EmptyState';
import Loader from '../Loader'; // Assuming you have a Loader component
import { BrandDescription, BrandLogo, Screenshot } from '../uiElements';
import { useEffect, useState } from 'react';

const MultipleScreensInCategory = ({ screens, viewmore = false }) => {
  const [loading, setLoading] = useState(true); // For loader
  const [isData, setIsData] = useState(null);  // Managing data presence
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hoveredApp, setHoveredApp] = useState("");
  
  useEffect(() => {

      setLoading(false);
      setIsData(screens);

  }, [screens]);

function handleMouseEnter(name:string){
	setCurrentIndex(1)
	setHoveredApp(name)
}
function handleMouseLeave(){
	setHoveredApp("")
	setCurrentIndex(0)

}
  return (
	<>
	<CategorySectionContainer>
	  {loading ? (
		// Show loader while data is being fetched
	
		  <Loader /> 
	
	  ) : (
		<>
		  {/* Check if data exists and is not empty */}
		  {isData && isData.length > 0 ? (
			<ElementsInCategoryContainer>
			  {isData.map(({ startScreens, name, country, logo, id, description }) => (
				<ScreenShotContainer key={id} viewmore={viewmore}>
				  <Link
					href={`/screens/${name.toLowerCase()}/screens/${id}`}
					passHref
					legacyBehavior
				  >
					<ScreenshotContainerWrapper onMouseEnter={()=>handleMouseEnter(name)}
                        onMouseLeave={handleMouseLeave}>
					  <ScreenshotContainerTop>
						<BrandLogo imageUrl={logo} />
						<BrandDescription
						  name={name}
						  brandcountry={country}
						  category={description}
						/>
					  </ScreenshotContainerTop>
					  <ScreenshotContainerBottom    >
				{hoveredApp===name?<Screenshot
						  key={startScreens[currentIndex]}
						  imgLink={startScreens[currentIndex]}
						  Name={name}
						/>:<Screenshot
						key={startScreens[0]}
						imgLink={startScreens[0]}
						Name={name}
					  />}	
					  </ScreenshotContainerBottom>
					</ScreenshotContainerWrapper>
				  </Link>
				</ScreenShotContainer>
			  ))}
			</ElementsInCategoryContainer>
		  ) : 
			// Show empty state if no data
			<Loader /> 
		  }
		</>
	  )}
	</CategorySectionContainer>
  </>
  );
};

const CategorySectionContainer = styled.div`
  margin: 1.5em 0;

  @media (min-width: 768px) {
    margin: 3em 0;
  }
`;

const ScreenshotContainerWrapper = styled.a``;

const ElementsInCategoryContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 1.5em auto;
  gap: 32px;
  width: 90%;
  @media (min-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) {
    width: 76%;
    margin: 0 auto 3em auto;
    gap: 32px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ScreenShotContainer = styled.div<{ viewmore: boolean }>`
  // additional styles
`;

const ScreenshotContainerTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  cursor: pointer;
`;

const ScreenshotContainerBottom = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  img {
    transition: transform 0.3s ease-in-out;  // Smooth transition effect
  }
  &:hover img {
    transform: scale(1.05);  // Slightly zoom in on hover
  }
`;

export default MultipleScreensInCategory;
